/* eslint-disable */
import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Index } from "elasticlunr"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled from '@emotion/styled'
import { injectIntl } from 'react-intl';
import { Flex, Box } from '../../common/Grid'

import Select from '../../common/Select'
import Input from '../../common/Input'

import faqicon from '../../../assets/images/faq.svg'


const AccordionItemStyled = styled(AccordionItem)`
  ${tw`border-solid border-0 border-b border-border-blue`}
  :focus{
    outline:none;
  }
`

const AccordionItemTitleStyled = styled(AccordionItemButton)`
  ${tw`font-montserrat font-normal text-2xl text-black py-8 pr-12 outline-none focus:shadow-outline cursor-pointer relative`}
  &:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -6px;
    width: 24px;
    height: 13px;
    background:url(${faqicon});
  }

  &[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }
`

const AccordionItemBodyStyled = styled(AccordionItemPanel)`
  ${tw`font-raleway font-medium text-lg leading-normal text-blog-text pl-8 pr-32`}

  a {
    ${tw`text-border-blue no-underline focus:underline hover:underline`}
  }

  @media screen and (max-width: 40em) {
    ${tw`pl-4 pr-8`}
  }
`

const SearchWrapper = styled.div`
  ${tw`py-6 mt-6`}
`

class FAQListing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
      results: [],
      category: ''
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

  }
  componentDidMount(){
    if(window.location.hash == '#pets'){
      let e = {
        target: {
          value: 'Pets'
        }
      }
      this.handleSelect(e)
    }
    if(window.location.hash == '#ami'){
      let e = {
        target: {
          value: 'Ami'
        }
      }
      this.handleSelect(e)
    }
  }

  handleSearch(e){
    this.setState({search:e.target.value,category:''})
    if(e.target.value.length > 2){
      let results = []
      for (var i = 0; i < this.props.original.length; i++) {
        if(
            this.props.original[i].node.question.toLowerCase().search(e.target.value.toLowerCase()) != -1
          ){
          results.push(this.props.original[i])
        }
      }
      this.setState({results:results})
    } else {
      this.setState({results:[]})
    }
  }

  handleSelect(e){
    this.setState({category:e.target.value,search:''})
    let results = []
    if(e.target.value.length){
      for (var i = 0; i < this.props.original.length; i++) {
        if(this.props.original[i].node.category == e.target.value){
          results.push(this.props.original[i])
        }
      }
      this.setState({results:results})
    } else {
      this.setState({results:[],category:''})
    }
  }

  render() {
    const { search, results, category } = this.state
    const { original, intl } = this.props
    const categories = original.map(data => data.node.category)
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    return (
      <div>
        <SearchWrapper>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box width={['100%', '100%', '100%', 'auto']} mb={[3,3,3,0]}>
                <Input
                  type="text"
                  name="search"
                  onChange={this.handleSearch}
                  value={search}
                  placeholder={intl.formatMessage({ id: 'search_faq' })}
                  icon="search"
                />
              </Box>

              <Box width={['100%', '100%', '100%', 'auto']}>
                <Select
                  name="category"
                  onChange={this.handleSelect}
                  value={category}
                >
                  <option value="" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'faq_category' }) }} />
                  {categories.filter(onlyUnique).map(faqCategory => (
                    faqCategory ? <option value={faqCategory}>{faqCategory}</option> : null
                  ))}
                </Select>
              </Box>
            </Flex>
        </SearchWrapper>

        {results.length ?
          (
            <Accordion allowZeroExpanded>
              {results.map(page => (
                <AccordionItemStyled key={page.node.id}>
                  <AccordionItemHeading>
                    <AccordionItemTitleStyled dangerouslySetInnerHTML={{ __html: page.node.question.replace(/[™®©]/g, '<sup>$&</sup>') }} />
                  </AccordionItemHeading>
                  <AccordionItemBodyStyled dangerouslySetInnerHTML={{ __html: page.node.answer.replace(/[™®©]/g, '<sup>$&</sup>') }} />
                </AccordionItemStyled>
              ))}
            </Accordion>
          )
          :
          (
            <Accordion allowZeroExpanded>
              {original.map(page => (
                page.node.question ?
                  (
                    <AccordionItemStyled key={page.node.guid}>
                      <AccordionItemHeading>
                        <AccordionItemTitleStyled dangerouslySetInnerHTML={{ __html: page.node.question.replace(/[™®©]/g, '<sup>$&</sup>') }} />
                      </AccordionItemHeading>
                      <AccordionItemBodyStyled dangerouslySetInnerHTML={{ __html: page.node.answer.replace(/[™®©]/g, '<sup>$&</sup>') }} />
                    </AccordionItemStyled>
                  )
                  : null
              ))}
            </Accordion>
          )
        }
      </div>
    )
  }
}

FAQListing.propTypes = {
  // eslint-disable-next-line
  index: PropTypes.any,
  locale: PropTypes.string.isRequired
}

export default injectIntl(FAQListing)
